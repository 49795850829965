import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CurdService } from 'src/app/services/curd.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  create_job_type: boolean = false;
  job_type_show = ''
  display_logo = true;
  users_department:any;

  constructor(
    private fromBuilder: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private curd: CurdService,
  ) {
    SidebarComponent.resizeEvent();
    SidebarComponent.menuToggleOnResize();
  }

  job_select_form = this.fromBuilder.group({
    job_type: new FormControl('', Validators.compose([
      Validators.required,])),
  });

  ngOnInit(): void {
    this.getUser()
  }

  getUser() {
    this.auth.getUser().subscribe(res => {
      if(res){
        const user_id = res.uid
        this.curd.show('users_list', user_id).get().subscribe(res => {
          const users_info = res.data();
          // @ts-ignore
          this.users_department =users_info.department;
        })}
    })
  }

  private static menuToggleOnResize() {
    let window_width = window.innerWidth;
    if (window_width < 1025) {
      document.getElementsByTagName('body')[0].classList.add('enlarge-menu', 'enlarge-menu-all');
    } else if (window_width < 1340) {
      document.getElementsByTagName('body')[0].classList.remove('enlarge-menu-all');
      document.getElementsByTagName('body')[0].classList.add('enlarge-menu');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('enlarge-menu', 'enlarge-menu-all');
    }
  }

  private static resizeEvent() {
    window.addEventListener('resize', function () {
      let window_width = window.innerWidth;
      if (window_width < 1025) {
        document.getElementsByTagName('body')[0].classList.add('enlarge-menu', 'enlarge-menu-all');
      } else if (window_width < 1340) {
        document.getElementsByTagName('body')[0].classList.remove('enlarge-menu-all');
        document.getElementsByTagName('body')[0].classList.add('enlarge-menu');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('enlarge-menu', 'enlarge-menu-all');
      }
    });
  }

  dashboard(){
    this.router.navigate(['dashboard']);
  }

  invoicePayments(){
    this.router.navigate(['invoice/payments']);
  }
  charges(){
    this.router.navigate(['charges/list']);
  }

  transportCompanies(){
    this.router.navigate(['transport-companies/list']);
  }

  doRefund(){
    this.router.navigate(['do-refund/list']);
  }

  creditNotesList(){
    this.router.navigate(['credit-notes/list']);
  }

  transportJobs(){
    this.router.navigate(['transport-jobs/list']);
  }

  vehicleTypes(){
    this.router.navigate(['vehicle-types/list']);
  }

  atfList(){
    this.router.navigate(['atf/list']);
  }

  createATF(){
    this.router.navigate(['atf/create']);
  }
  invoiceQuotation(){
    this.router.navigate(['invoice/quotation-items/list']);
  }
  dashboardClearing(){
    this.router.navigate(['clearing-staff/dashboard']);
  }

createQuotation(){
  this.router.navigate(['quotation/list']);
}

  declarationsList(){
    this.router.navigate(['declarations-management/list']);
  }

  packageList() {
    this.router.navigate(['package-type-management/list']);
  }

  atfCategory(){
this.router.navigate(['atf-category/list'])
  }

  invoiceList(){
    this.router.navigate(['invoice/list'])
  }
  seaFreightCargo() {
    this.router.navigate(['jobs/list']);
  }

  officeOfEntry(){
    this.router.navigate(['office-of-entry/list']);
  }

  seaFreightExport() {
    this.router.navigate(['sea-freight-export/list']);
  }

  portsList() {
    this.router.navigate(['ports-management/list']);
  }

  vesselList() {
    this.router.navigate(['vessal-management/list']);
  }

  seaFreightImport() {
    this.router.navigate(['sea-freight-import/list']);
  }

  airFrightCargo() {
    this.router.navigate(['air-freight-cargo/list']);
  }

  airFrightExport() {
    this.router.navigate(['air-freight-export/list']);
  }
  shippingList() {
    this.router.navigate(['shipping-line-management/list']);
  }

  exporterList() {
    this.router.navigate(['exporter/list']);
  }

  unitsList() {
    this.router.navigate(['units/list']);
  }

  hsList() {
    this.router.navigate(['hs-code-management/list']);
  }

  userList() {
    this.router.navigate(['users/list']);
  }

  departmentsList() {
    this.router.navigate(['department-management/list']);
  }

  emailTemplateList() {
    this.router.navigate(['email-template-management/list']);
  }

  processList() {
    this.router.navigate(['process-management/list']);
  }

  customers() {
    this.router.navigate(['customer-management/list']);
  }

  triggerList() {
    this.router.navigate(['email-trigger-management/list']);
  }

  banksList() {
    this.router.navigate(['bank-management/list']);
  }

  containerTypeList() {
    this.router.navigate(['container-type-management/list']);
  }

  containerSizeList() {
    this.router.navigate(['container-size-management/list']);
  }

  procedureList() {
    this.router.navigate(['procedure-code-management/list']);
  }

  clearingStatus() {
    this.router.navigate(['clearing-staff/status-list']);
  }
}

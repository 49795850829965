import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { OffCanvasComponent } from './off-canvas/off-canvas.component';

import { FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, SidebarComponent, OffCanvasComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule
    ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    OffCanvasComponent
  ]
})
export class ComponentsModule { }

<!-- Top Bar Start -->
<div class="topbar">
  <nav class="navbar-custom" id="navbar-custom">
    <ul class="list-unstyled topbar-nav float-end mb-0">
      <div class="box-search">
        <li class="hide-phone app-search">
          <form role="search" action="#" method="get">
            <input type="search" name="search" class="form-control top-search mb-0" [(ngModel)]="job_number" placeholder="Type Job Number...">
            <button type="submit" (click)="searchJob()"><i class="ti ti-search"></i></button>
          </form>
        </li>
      </div>
      <li class="dropdown">
        <a class="nav-link dropdown-toggle nav-user" data-bs-toggle="dropdown" role="button"
          aria-haspopup="false" aria-expanded="false">
          <div class="d-flex align-items-center">
            <img src="assets/images/users/user-4.jpg" alt="profile-user" class="rounded-circle me-2 thumb-sm" />
            <div>
              <small class="d-none d-md-block font-11"  (click)="userShow()">{{user_name}}</small>
              <span class="d-none d-md-block fw-semibold font-12">{{user_role}} <i
                  class="mdi mdi-chevron-down"></i></span>
            </div>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <div class="dropdown-divider mb-0"></div>
          <a class="dropdown-item" (click)="logOutUser()"><i class="ti ti-power font-16 me-1 align-text-bottom"
             ></i> Logout</a>
        </div>
      </li>
    </ul>
    <ul class="list-unstyled topbar-nav mb-0">
      <li>
        <button class="nav-link button-menu-mobile nav-icon" id="togglemenu" (click)="toggleMenu()">
        <i class="ti ti-menu-2"></i>
        </button>
      </li>
    </ul>
  </nav>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1" id="Appearance" aria-labelledby="AppearanceLabel">
  <div class="offcanvas-header border-bottom">
    <h5 class="m-0 font-14" id="AppearanceLabel">Appearance</h5>
    <button type="button" class="btn-close text-reset p-0 m-0 align-self-center" data-bs-dismiss="offcanvas"
      aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <h6>User Management</h6>
    <div class="p-2 text-start mt-3">
      <div class="form-check form-switch mb-2">
        <input class="form-check-input" type="checkbox" id="settings-switch1">
        <label class="form-check-label" for="settings-switch1">Create User </label>
      </div>
      <div class="form-check form-switch mb-2">
        <input class="form-check-input" type="checkbox" id="settings-switch2" checked>
        <label class="form-check-label" for="settings-switch2">Users List</label>
      </div>
    </div>
    <h6>General Settings</h6>
    <div class="p-2 text-start mt-3">
      <div class="form-check form-switch mb-2">
        <input class="form-check-input" type="checkbox" id="settings-switch4">
        <label class="form-check-label" for="settings-switch4">Show me Online</label>
      </div>
      <div class="form-check form-switch mb-2">
        <input class="form-check-input" type="checkbox" id="settings-switch5" checked>
        <label class="form-check-label" for="settings-switch5">Status visible to all</label>
      </div>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="settings-switch6">
        <label class="form-check-label" for="settings-switch6">Notifications Popup</label>
      </div>
    </div>
  </div>
</div>
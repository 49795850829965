<div class="left-sidebar">
  <div class="brand">
    <a href="index.html" class="logo">
      <span>
        <img src="assets/images/logo.png" id="logo-img" class="logo-lg-hs" alt="main-logo">
        <img src="assets/images/logo-sm.png" style="display: none" id="logo-img-sm" class="logo-lg-new" alt="main-logo">
      </span>
    </a>
  </div>
  <div class="menu-content h-100" data-simplebar>
    <div class="menu-body navbar-vertical tab-content">
      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <ul class="navbar-nav" *ngIf="users_department=='Clearing Staff'">
          <li class="menu-label mt-0"><span>Main</span></li>
          <li class="nav-item" >
            <a class="nav-link" (click)="dashboardClearing()" data-bs-toggle="collapse" role="button" aria-expanded="false"
              aria-controls="sidebarAnalytics">
              <i class="ti ti-home menu-icon"></i>
              <span>Dashboards</span>
            </a>
          </li>
        </ul>
        <ul class="navbar-nav" *ngIf="users_department !='Clearing Staff'">
          <li class="menu-label mt-0"><span>Main</span></li>
          <li class="nav-item" >
            <a class="nav-link" (click)="dashboard()" data-bs-toggle="collapse" role="button" aria-expanded="false"
              aria-controls="sidebarAnalytics">
              <i class="ti ti-home menu-icon"></i>
              <span>Dashboards</span>
            </a>
            <div class="collapse " id="sidebarDashboards">
            </div>
          </li>

          <li class="menu-label mt-0"><span>Jobs</span></li>
          <li class="nav-item">
            <a class="nav-link" (click)="seaFreightCargo()"> Sea
              Freight Cargo</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="seaFreightExport()"> Sea
              Freight Export</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="seaFreightImport()"> Sea
              Freight Import</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="airFrightCargo()"> Air
              Freight Cargo</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="airFrightExport()"> Air
              Freight Export </a>
          </li>
          <li class="menu-label mt-0"><span>Invoicing</span></li>
          <li class="nav-item">
            <a class="nav-link" (click)="createQuotation()">Quotation</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="invoiceList()">Invoice-list</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="invoicePayments()">Payments</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="creditNotesList()">Credit Notes</a>
          </li>
          <li class="menu-label mt-0"><span> Information </span></li>
          <li class="nav-item">
            <a class="nav-link" (click)="exporterList()" role="button" aria-expanded="false">
              <i class="ti ti-plane menu-icon"></i>
              <span>Exporter Management </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="customers()" role="button" aria-expanded="false">
              <i class="ti ti-friends menu-icon"></i>
              <span>Consignee Management </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="shippingList()" role="button" aria-expanded="false">
              <i class="ti ti-sailboat menu-icon"></i>
              <span>Shipping Line Management </span>
            </a>
          </li>
          <li class="menu-label mt-0"><span> Setting </span></li>
          <li class="nav-item">
            <a class="nav-link" href="#transportdepartment" data-bs-toggle="collapse" role="button" aria-expanded="false"
              aria-controls="transportdepartment">
              <i class="ti ti-briefcase menu-icon"></i>
              <span>Transport Management</span>
            </a>
            <div class="collapse " id="transportdepartment">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link" (click)="transportCompanies()" role="button" aria-expanded="false">
                    <i class="ti ti-package menu-icon"></i>
                    <span>Transport Companies</span>
                  </a>
                </li>
              </ul>
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link" (click)="transportJobs()" role="button" aria-expanded="false">
                    <i class="ti ti-package menu-icon"></i>
                    <span>Transport Jobs</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#atfdata" data-bs-toggle="collapse" role="button" aria-expanded="false"
              aria-controls="atfdata">
              <i class="ti ti-briefcase menu-icon"></i>
              <span>ATF</span>
            </a>
            <div class="collapse " id="atfdata">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link"  (click)="createATF()"  role="button" aria-expanded="false">
                    <i class="ti ti-package menu-icon"></i>
                    <span>ATF Create</span>
                  </a>
                </li>
              </ul>
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link" (click)="atfList()" role="button" aria-expanded="false">
                    <i class="ti ti-package menu-icon"></i>
                    <span> ATF List</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#dorefund" data-bs-toggle="collapse" role="button" aria-expanded="false"
              aria-controls="dorefund">
              <i class="ti ti-briefcase menu-icon"></i>
              <span>Do Refund Management</span>
            </a>
            <div class="collapse " id="dorefund">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link" (click)="doRefund()" role="button" aria-expanded="false">
                    <i class="ti ti-package menu-icon"></i>
                    <span>Do Refund List</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#masterData" data-bs-toggle="collapse" role="button" aria-expanded="false"
              aria-controls="masterData">
              <i class="ti ti-briefcase menu-icon"></i>
              <span>Master Data Management</span>
            </a>
            <div class="collapse " id="masterData">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link" (click)="charges()" role="button" aria-expanded="false">
                    <i class="ti ti-list-numbers menu-icon"></i>
                    <span>Charges</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="vehicleTypes()" role="button" aria-expanded="false">
                    <i class="ti ti-package menu-icon"></i>
                    <span>Vehicle Types</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="invoiceQuotation()" role="button" aria-expanded="false">
                    <i class="ti ti-package menu-icon"></i>
                    <span>Expenses</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="clearingStatus()" role="button" aria-expanded="false">
                    <i class="ti ti-package menu-icon"></i>
                    <span>Clearing Status</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="officeOfEntry()" role="button" aria-expanded="false">
                    <i class="ti ti-briefcase menu-icon"></i>
                    <span>Office Of Entry</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="containerSizeList()" role="button" aria-expanded="false">
                    <i class="ti ti-list-numbers menu-icon"></i>
                    <span>Container Size Management</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="containerTypeList()" role="button" aria-expanded="false">
                    <i class="ti ti-letter-c menu-icon"></i>
                    <span>Container Type Management</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="declarationsList()" role="button" aria-expanded="false">
                    <i class="ti ti-letter-d menu-icon"></i>
                    <span>Type Of Declarations Management</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="banksList()" role="button" aria-expanded="false">
                    <i class="ti ti-building-bank menu-icon"></i>
                    <span>Bank Management </span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="portsList()" role="button" aria-expanded="false">
                    <i class="ti ti-anchor menu-icon"></i>
                    <span>Ports Management</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="vesselList()" role="button" aria-expanded="false">
                    <i class="ti ti-letter-v menu-icon"></i>
                    <span>Vessel Management</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="packageList()" role="button" aria-expanded="false">
                    <i class="ti ti-package menu-icon"></i>
                    <span>Package Types Management</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="hsList()" role="button" aria-expanded="false">
                    <i class="ti ti-heading menu-icon"></i>
                    <span>HS Code Management </span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="unitsList()" role="button" aria-expanded="false">
                    <i class="ti ti-ruler-2 menu-icon"></i>
                    <span>Units Management </span>
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" (click)="procedureList()" role="button" aria-expanded="false">
                    <i class="ti ti-letter-p menu-icon"></i>
                    <span>Procedure Code Management </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="userList()">
              <i class="ti ti-user menu-icon"></i>
              <span>User Management </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="departmentsList()">
              <i class="ti ti-building menu-icon"></i>
              <span>Department Management </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#emailData" data-bs-toggle="collapse" role="button" aria-expanded="false"
              aria-controls="emailData">
              <i class="ti ti-briefcase menu-icon"></i>
              <span>Trigger Management</span>
            </a>
            <div class="collapse " id="emailData">
              <ul class="nav flex-column">
                <li class="nav-item">
                  <a class="nav-link" (click)="triggerList()" role="button" aria-expanded="false">
                    <i class="ti ti-building-bank menu-icon"></i>
                    <span>Email Trigger Management </span>
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {FirestoreService} from './firestore.service';

@Injectable({
  providedIn: 'root'
})
export class CurdService {

  constructor(private firestoreService: FirestoreService) {
  }

  index(collection_name: string) {
    return this.firestoreService.colWithIds$(collection_name);
  }


  indexPromise(collection_name: string) {
    return new Promise(resolve => {
      this.firestoreService.colWithIds$(collection_name).subscribe(res => {
        resolve(res);
      });
    });
  }

  where(collection_name: string, condition: any) {
    return this.firestoreService.colWithIds$(collection_name, condition);
  }

  store(collection_name: string, data: any) {
    return this.firestoreService.add(collection_name, data);
  }

  setWithID(collection_name: string, data: any) {
    return this.firestoreService.set(collection_name, data);
  }

  show(collection_name: string, document_id: any) {
    return this.firestoreService.doc(`${collection_name}/${document_id}`);
  }

  showPromise(collection_name: string, document_id: any) {
    return new Promise(resolve => {
      this.firestoreService.doc(`${collection_name}/${document_id}`).get().subscribe(res => {
        resolve(res.data());
      });
    });
  }

  showPromiseUnsubscribed(collection_name: string, document_id: any) {
    return new Promise(resolve => {
      let doc = this.firestoreService.doc(`${collection_name}/${document_id}`).get().subscribe(res => {
        doc.unsubscribe();
        doc = null;
        resolve(res.data());
      });
    });
  }

  getUnitData(collection_name: string, unit_name: any) {
    return new Promise(resolve => {
      this.firestoreService.colWithIds$(`${collection_name}/${unit_name}`).subscribe(res => {
        console.log(res);
        resolve(res);
      });
    });
  }

  showRealTime(collection_name: string, document_id: any) {
    return this.firestoreService.doc$(`${collection_name}/${document_id}`);
  }

  update(collection_name: string, document_id: string, data: any) {
    return this.firestoreService.update(`${collection_name}/${document_id}`, data);
  }

  delete(collection_name: string, document_id: string) {
    return this.firestoreService.delete(`${collection_name}/${document_id}`);
  }

  uploadImage(image: string, file_name: string, folder: string) {
    return this.firestoreService.uploadImage(image, file_name, folder);
  }

}


import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { CurdService } from './curd.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private curd: CurdService,
    private auth: AuthService
  ) {
  }

  canActivate(
    router: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.authService.getUser().subscribe(user => {
      if (!user) {
        console.log('Not Logged')
        this.router.navigate(['login']);
      } else {
        this.curd.show('users_list', user.uid).get().subscribe(res => {
          if (!res.data()) {
            this.auth.logoutUser().then(() => {
              this.router.navigate(['login']);
            });
          }
        })
      }
    })
    return true;
  }
}

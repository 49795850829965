import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CurdService } from 'src/app/services/curd.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  users_info: any = [];
  fillted_user: any = [];
  user_name: any;
  user_role: any;
  job_number: any;
  user_id: any;

  constructor(
    private auth: AuthService,
    private curd: CurdService,
    private router: Router,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.getUser();
  }

  searchJob() {
    this.curd.index('jobs_list').subscribe(res => {
      let jobs_info = res;
      jobs_info = jobs_info.filter(obj => obj.job_no == this.job_number);
      if (jobs_info.length == 1) {
        const document_id = jobs_info[0].id
        console.log(jobs_info[0].id)
        this.router.navigate(['jobs/show', document_id]);
        this.job_number = null
      }
      else {
        this.toast.showError('No results found');
        this.job_number = null
      }
    });
  }

  userShow() {
    this.router.navigate(['user/show', this.user_id]);
  }

  getUser() {
    this.auth.getUser().subscribe(res => {
      if (res) {
        this.user_id = res.uid
        this.curd.show('users_list', this.user_id).get().subscribe(res => {
          this.users_info = res.data();
          // @ts-ignore
          this.user_name = this.users_info.full_name;
          // @ts-ignore
          this.user_role = this.users_info.role
        })
      }
    })
  }

  toggleMenu() {
    let logo = document.getElementById('logo-img');
    let logo_sm = document.getElementById('logo-img-sm');
    let display = window.getComputedStyle(document.querySelector('#logo-img')).display
    if (display == 'inline') {
      logo.style.display = "none";
      logo_sm.style.display = "inline";
    } else {
      logo.style.display = "inline";
      logo_sm.style.display = "none";
    }
    document.body.classList.toggle('enlarge-menu');
  }

  logOutUser() {
    this.auth.logoutUser();
  }
}

import {Injectable} from '@angular/core';
import {HotToastService} from '@ngneat/hot-toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toast: HotToastService
  ) {
  }

  inputError() {
    this.toast.error('Invalid Input. Try Again');
  }

  showError(message: string) {
    this.toast.error(message);
  }

  error() {
    this.toast.error('Something Went Wrong');
  }

  success() {
    this.toast.success('Data Saved Successfully');
  }

  customSuccess(messge: string) {
    this.toast.success(messge);
  }

  uploading() {
    return this.toast.loading('Uploading, Please Wait...');
  }

  sending() {
    return this.toast.loading('Sending Email, Please Wait...');
  }

  observe(loading_message: string, success_message: string, error_message: string) {
    return this.toast.observe(
      {
        loading: loading_message,
        success: success_message,
        error: error_message,
      }
    );
  }

  dropbox(name) {
    return this.toast.observe(
      {
        loading: `Uploading ${name}`,
        success: `${name} Upload Success`,
        error: `${name} Upload Failed`,
      }
    );
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCElpelMSYvq15EgxEf8gc9Snb0pBvm4pA",
    authDomain: "enhancehs-38e5d.firebaseapp.com",
    projectId: "enhancehs-38e5d",
    storageBucket: "enhancehs-38e5d.appspot.com",
    messagingSenderId: "382498443122",
    appId: "1:382498443122:web:271bc454b256892ca557eb"
  },
  api_host: undefined,
  pdf_co_key : "awsadmin@hscargo.lk_d146ec2c8704ce61f2e5e8e64dbd4d9a6f2d7cb271b81f22ec94b09fb325124a274774ee"
  // pdf_co_key : "clickylk@gmail.com_f9a35b4ed9ab8c38fbc5e44e706b4db3ec0611658777fec77faed0e841a5825e8f8d8339"
};

export const environmentEmail = {
  apiUrl: 'https://us-central1-hscargo-a6bd9.cloudfunctions.net'
  //apiUrl: 'http://localhost:5001/hscargo-a6bd9/us-central1'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
